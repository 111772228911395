export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN = `${BASE_URL}admin/sign-in`;
export const SIGN_UP = `${BASE_URL}admin/sign-up`;
export const SEND_PASSWORD_OTP = 'admin/reset-password';
export const VERIFY_RESET_PASSWORD_OTP = `${BASE_URL}admin/verify-otp`;
export const CHANGE_PASSWORD = `${BASE_URL}admin/change-password`;
export const GET_PROFILE = 'admin/profile';
export const GET_USERS_COUNT = 'admin/users/all/count';
export const GET_MENTORS_COUNT = 'admin/mentors/all/count';
export const ADD_MENTORS = 'admin/mentors/add';
export const GET_ALL_MENTORS = 'admin/mentors/all';
export const DELETE_MENTOR = (id = '') => `admin/mentors/${id}/delete`;
export const GET_MENTOR = (id = '') => `admin/mentors/${id}`;
export const UPDATE_MENTOR = (id = '') => `admin/mentors/${id}/update`;
export const ADD_MEMBER = 'admin/teams/add';
export const GET_ALL_MEMBERS = 'admin/teams/all';
export const DELETE_MEMBER = (id = '') => `admin/teams/${id}/delete`;
export const GET_MEMBER = (id = '') => `admin/teams/${id}`;
export const UPDATE_MEMBER = (id = '') => `admin/teams/${id}/update`;
export const GET_ALL_USERS = 'admin/users/all';
export const DELETE_USER = (id = '') => `admin/users/${id}/delete`;
export const GET_BOOTCAMP_USER = 'admin/users/bootcamps';
export const ADD_TESTIMONIAL = 'admin/testimonials/add';
export const GET_ALL_TESTIMONIAL = 'admin/testimonials/all';
export const ADD_EVENT = 'admin/events/add';
export const GET_ALL_EVENTS = 'admin/events/all';
export const GET_EVENT = (id = '') => `admin/events/${id}`;
export const UPDATE_EVENT = (id = '') => `admin/events/${id}/update`;
export const DELETE_EVENT = (id = '') => `admin/events/${id}/delete`;

export const ADD_PROJECT = 'admin/projects/add';
export const GET_ALL_PROJECTS = 'admin/projects/all';
export const DELETE_PROJECT = (id = '') => `admin/projects/${id}`;
export const UPDATE_PROJECT = (id = '') => `admin/projects/${id}/update`;
export const GET_PROJECT = (id = '') => `admin/projects/${id}`;
export const ADD_COHORT = 'admin/cohorts/add';
export const UPDATE_COHORT = (id = '') => `admin/cohorts/${id}/update`;
export const GET_ALL_COHORTS = 'admin/cohorts/all';
export const DELETE_COHORT = (id = '') => `admin/cohorts/${id}/delete`;
export const ADD_BOOTCAMP = 'admin/bootcamps/add';
export const GET_ALL_BOOTCAMPS = 'admin/bootcamps/all';
export const UPDATE_BOOTCAMP = (id = '') => `admin/bootcamps/${id}/update`;
export const DELETE_BOOTCAMP = (id = '') => `admin/bootcamps/${id}/delete`;
export const GET_COHORT = (id = '') => `admin/cohorts/${id}`;
export const UPLOAD_AVATAR = `admin/upload-avatar`;
export const ADD_CHALLENGE = 'admin/challenges/add';
export const GET_ALL_CHALLENGE = 'admin/challenges/all';

export const BROADCAST_EVENT = (id = '') => `admin/events/${id}/broadcast`;
export const DELETE_CHALLENGE = (id = '') => `admin/challenges/${id}/delete`;
export const UPDATE_CHALLENGE = (id = '') => `admin/challenges/${id}/update`;
export const DELETE_TESTIMONIAL = (id = '') =>
  `admin/testimonials/${id}/delete`;
export const GET_ALL_USERS_CHALLENGE = 'admin/users/challenges';
export const GET_USER_CHALLENGE_PROFILE = (id = '', type = '') =>
  `admin/users/${id}/challenges?challengeType=${type}`;
export const GET_USER_CHALLENGE_PROGRESS = (type = '', id = '') =>
  `admin/users/progress?challengeType=${type}&userId=${id}`;
export const ADD_DESIGNER = '/admin/designers/add';
export const GET_ALL_DESIGNERS = 'admin/designers/all';
export const DELETE_DESIGNER = (id = '') => `admin/designers/${id}`;
export const GET_ACTIVITY_LOGS = 'activity-logs/all';
export const GET_ROLES = 'admin/roles/all';
export const UPDATE_ROLE = (id = '') => `admin/roles/${id}/update`;
export const INVITE_ADMIN = 'admin/invite-admin';
export const CREATE_ROLE = '/admin/roles/create';
export const PRE_PERMISSIONS = 'admin/get-permissions';
export const DEL_ROLES = (id = '') => `admin/roles/${id}/delete`;
export const GET_ROLE = (id = '') => `admin/roles/${id}`;
export const GET_ALL_ADMINS = 'admin/all';
export const GET_ADMIN = (id = '') => `admin/${id}`;
export const DELETE_ADMIN = (id = '') => `admin/${id}/delete`;
export const UPDATE_ADMIN = (id = '') => `admin/${id}/update`;
export const UPDATE_PROFILE = 'admin/profile/update';

//
