import { useState } from 'react';
import { data } from '../../common/constant';
import logo from '../../../assets/ux-logo.png';
import { NavLink, useLocation } from 'react-router-dom';
import { sideScroll, useLogOut } from '../../../utils/helpers';
import { Box, Flex, Text, Image, Avatar, Spinner } from '@chakra-ui/react';
import { AiOutlineLogout } from 'react-icons/ai';

const SideBar = () => {
  const logout = useLogOut();
  const [isLoading, setIsLoading] = useState(false);

  const user = JSON.parse(sessionStorage.getItem('user'));
  const permissions = user?.data?.permissions;

  const action = () => {
    setIsLoading(true);
    setTimeout(() => {
      logout();
      setIsLoading(false);
    }, 1000);
  };

  const activeStyle = {
    backgroundColor: '#DDD8FB',
    alignItems: 'center',
    display: 'flex',
    gap: '10px',
    color: '#101928',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0 0 8px 0',
    padding: '12px 16px ',
    borderRadius: '4px',
  };

  // Filter based on permissions
  const filteredData = data?.filter((item) => {
    switch (item.title) {
      case 'Users':
        return permissions?.includes('MANAGE USERS');
      case 'Mentors':
        return permissions?.includes('MANAGE MENTORS');
      case 'Team Members':
        return permissions?.includes('MANAGE TEAM');
      case 'Projects':
        return permissions?.includes('MANAGE PROJECTS');
      case 'Admins':
        return permissions?.includes('MANAGE ADMIN');
      case 'Testimonials':
        return permissions?.includes('MANAGE TESTIMONIALS');
      case 'Cohort':
        return permissions?.includes('MANAGE COHORTS');
      case 'Events':
        return permissions?.includes('MANAGE EVENTS');
      case 'Challenge':
        return permissions?.includes('MANAGE CHALLENGES');
      case 'Designers of the week':
        return permissions?.includes('MANAGE DESIGNERS');
      default:
        return true; // For items that don't need special permissions
    }
  });

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      position={'fixed'}
      zIndex="100"
      bg="#FFFFFF"
      w="285px"
      color="#101928"
      style={{ fontFamily: 'Inter' }}
    >
      <Box h="100vh" overflowY="scroll" borderRight="2px solid #E4E7EC">
        <Box bg="#FFFFFF" pos="sticky" top={0} pl="20px" pr="18px" pt="20px">
          <Flex pb="20px" align="center" fontSize="22px" gap="10px">
            <Image src={logo} w="40px" h="38px" alt="logo" />
            <Box fontWeight={600}>UX Thrive</Box>
          </Flex>
        </Box>
        <Box px="20px" flex="1">
          {filteredData?.map((item, i) => (
            <Box
              key={i}
              _hover={{
                backgroundColor: '#DDD8FB',
                borderRadius: '4px',
              }}
            >
              <NavLink
                to={item.path}
                style={({ isActive }) =>
                  isActive
                    ? { ...activeStyle }
                    : {
                        ...activeStyle,
                        backgroundColor: '',
                        color: '#101928',
                      }
                }
              >
                <Box fontSize="20px">{item.icon}</Box>
                {item.title}
              </NavLink>
            </Box>
          ))}
        </Box>
        <Box
          mt="40px"
          // position="absolute"
          // bottom="20"
          left={0}
          right={0}
          borderTop="1px solid #314169"
          px={6}
          fontSize="14px"
          align="center"
          py="30px"
          fontWeight={400}
          cursor="pointer"
          onClick={action}
        >
          {isLoading ? (
            <Flex gap="8px" color="red" align="center">
              <Spinner size="sm" /> Logging Out
            </Flex>
          ) : (
            <Flex gap="8px" align="center" color="#b4b4b4">
              <AiOutlineLogout color="#B4B4B4" />
              Log Out
            </Flex>
          )}
        </Box>
      </Box>
    </Flex>
  );
};
export default SideBar;
