export const PUBLIC_PATHS = {
  LOGIN: '/login',
  CHANGE_PASS: '/change-password',
  RESET_PASS: '/reset-password',
  VERIFY_OTP: '/verify-otp',
};

export const PRIVATE_PATHS = {
  DASHBOARD: '/dashboard',
  USER: '/users',
  USER_PROFILE: '/users/:id',
  MENTORS: '/mentors',
  TEAM: '/team-members',
  EVENTS: '/events',
  VIEW_EVENTS: '/events/:id',
  PROJECTS: '/projects',
  TESTIMONIALS: '/testimonials',
  COHORTS: '/cohort',
  VIEW_BOOTCAMPS: '/cohort/:id',
  SETTINGS: '/settings',
  CHALLENGE: '/challenge',
  DESIGNERS: '/designers',
  ADMINS: '/admins',
  MANAGE_ROLES: '/admins/manage-roles',
  CREATE_ROLES: '/admins/create-roles',
};
