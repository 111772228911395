import { lazy } from 'react';
import { PRIVATE_PATHS, PUBLIC_PATHS } from './constants';
import WithSuspense from '../components/Layout/Loaders/WithSupense';
import { Navigate } from 'react-router-dom';

const { LOGIN, RESET_PASS, CHANGE_PASS, VERIFY_OTP } = PUBLIC_PATHS;

const {
  DASHBOARD,
  USER,
  MENTORS,
  USER_PROFILE,
  TEAM,
  EVENTS,
  TESTIMONIALS,
  PROJECTS,
  SETTINGS,
  COHORTS,
  CHALLENGE,
  VIEW_BOOTCAMPS,
  VIEW_EVENTS,
  ADMINS,
  DESIGNERS,
  MANAGE_ROLES,
  CREATE_ROLES,
} = PRIVATE_PATHS;

const Login = WithSuspense(lazy(() => import('../pages/Auth/Login')));
const ChangePassword = WithSuspense(
  lazy(() => import('../pages/Auth/ChangePassword'))
);
const ResetPassword = WithSuspense(
  lazy(() => import('../pages/Auth/ResetPassword'))
);

const VerifyOtp = WithSuspense(
  lazy(() => import('../pages/Auth/VerifyOtp.js'))
);

const Dashboard = WithSuspense(lazy(() => import('../pages/Dashboard')));

const Users = WithSuspense(lazy(() => import('../pages/User')));

const UserProfile = WithSuspense(
  lazy(() => import('../components/data/User/ViewUserProfile.js'))
);

const Mentors = WithSuspense(lazy(() => import('../pages/Mentors')));

const Team = WithSuspense(lazy(() => import('../pages/Members.js')));

const Events = WithSuspense(lazy(() => import('../pages/Events.js')));

const ViewEvents = WithSuspense(lazy(() => import('../pages/EventPreview.js')));

const Testimonials = WithSuspense(
  lazy(() => import('../pages/Testimonials.js'))
);

const Cohorts = WithSuspense(lazy(() => import('../pages/Cohort.js')));
const ViewBootCamps = WithSuspense(lazy(() => import('../pages/Bootcamps.js')));

const Projects = WithSuspense(lazy(() => import('../pages/Project.js')));

const Settings = WithSuspense(lazy(() => import('../pages/Settings.js')));
const Challenge = WithSuspense(lazy(() => import('../pages/Challenge.js')));
const Designers = WithSuspense(lazy(() => import('../pages/Designers.js')));

const Admins = WithSuspense(lazy(() => import('../pages/Admins')));

const ManageRoles = WithSuspense(lazy(() => import('../pages/ManageRoles')));

const CreateRoles = WithSuspense(lazy(() => import('../pages/CreateRoles')));

export const PUBLIC_ROUTES = [
  { path: LOGIN, element: <Login /> },
  { path: RESET_PASS, element: <ResetPassword /> },
  { path: CHANGE_PASS, element: <ChangePassword /> },
  { path: VERIFY_OTP, element: <VerifyOtp /> },
  { path: '*', element: <Navigate to="/login" replace /> },
];

export const PRIVATE_ROUTES = [
  { path: DASHBOARD, element: <Dashboard /> },
  { path: USER, element: <Users /> },
  { path: USER_PROFILE, element: <UserProfile /> },
  { path: MENTORS, element: <Mentors /> },
  { path: TEAM, element: <Team /> },
  { path: EVENTS, element: <Events /> },
  { path: VIEW_EVENTS, element: <ViewEvents /> },
  { path: TESTIMONIALS, element: <Testimonials /> },
  { path: PROJECTS, element: <Projects /> },
  { path: COHORTS, element: <Cohorts /> },
  { path: VIEW_BOOTCAMPS, element: <ViewBootCamps /> },
  { path: CHALLENGE, element: <Challenge /> },
  { path: DESIGNERS, element: <Designers /> },
  { path: ADMINS, element: <Admins /> },
  { path: SETTINGS, element: <Settings /> },
  { path: MANAGE_ROLES, element: <ManageRoles /> },
  { path: CREATE_ROLES, element: <CreateRoles /> },
  { path: '*', element: <Navigate to="/dashboard" replace /> },
];
