import { extendTheme } from '@chakra-ui/react';

export const customTheme = extendTheme({
  fonts: {
    heading: `Inter`,
    body: `Inter`,
  },
  components: {
    Input: {
      variants: {
        primary: {
          field: {
            bgColor: '#fff',
            borderRadius: '10px',
            color: '#000',
            _placeholder: {
              color: 'gray',
              padding: '5',
            },
          },
        },
        alternate: {
          field: {
            bgColor: '#3B2770',
            border: '1px solid #E0E0E0',
            borderRadius: '5px',
            color: '#000',
            _placeholder: {
              color: '#333333',
              padding: '10px',
              fontSize: '13px',
            },
          },
        },
        secondary: {
          field: {
            bgColor: '#F2F2F2',
            borderRadius: '10px',
            color: '#000',
            _placeholder: {
              color: '#BDBDBD',
            },
          },
        },
      },
      defaultProps: {
        variant: 'primary',
      },
    },
    Select: {
      variants: {
        primary: {
          field: {
            bgColor: '#F1EBF9',
            borderRadius: '10px',
            cursor: 'pointer',
          },
        },
        secondary: {
          field: {
            rounded: 'full',
            borderRadius: '10px',
            cursor: 'pointer',
            bg: '#fafafa',
            border: '1px solid #E0E0E0',
          },
        },
      },
      defaultProps: {
        variant: 'primary',
      },
    },
    Textarea: {
      variants: {
        primary: {
          bg: '#f2f2f2',
          color: '#000',
          borderRadius: '10px',
          _placeholder: {
            color: '#BDBDBD',
          },
        },
        secondary: {
          bg: '#fff',
          color: '#000',
          border: '1px solid gray',
          _placeholder: {
            color: '#BDBDBD',
          },
        },
      },
      defaultProps: {
        variant: 'primary',
      },
    },
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                transform: 'scale(0.85) translateY(-24px)',
                color: '#000000',
                borderRadius: '5px',
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label':
              {
                transform: 'scale(0.85) translateY(-24px)',
                color: '#000000',
                borderRadius: '5px',
              },
            label: {
              color: '#C4C4C4',
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
      },
    },
    Button: {
      variants: {
        primary: {
          border: '1px solid #3B2770',
          bgColor: '#3B2770',
          color: '#fff',
          fontWeight: '300',
          _hover: {
            bgColor: '#fff',
            color: '#3B2770',
          },
          _disabled: {
            _hover: {
              border: '1px solid #7B47CC',
              color: '#3B2770',
            },
          },
          _focus: {
            bgColor: '#3B2770',
            color: '#ffff',
          },
          _active: {
            bgColor: '#3B2770',
            color: '#fff',
          },
        },
        secondary: {
          bgColor: '#3B2770',
          size: 'md',
          border: '1px solid',
          color: '#fff',
          fontWeight: '300',
          _hover: {
            bgColor: 'blueColor',
            color: '#fff',
          },
          _disabled: {
            _hover: {
              border: '1px solid #F3C948',
              color: 'orangeColor',
            },
          },
          _focus: {
            bgColor: '',
            color: '#fff',
          },
          _active: {
            bgColor: 'blueColor',
            color: '#fff',
          },
        },
      },
      defaultProps: {
        variant: 'primary',
      },
    },
  },

  colors: {
    purpleColor: '#0F172B',
    blueColor: '#3B2770',
    semiPurple: '#F6F3FC',
    gradient: 'linear-gradient(90deg, #F1EBF9 0%, #FDF8E7 79.13%)',
    semiOrange: '#FDF8E7',
    orangeColor: '#F3C948',
    color: '#000',
  },
});
