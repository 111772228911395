import axios from 'axios';
import * as API from '../url';
import axiosInstance from '../axiosInstance';

export const login = async (body) => {
  const res = await axios.post(API.LOGIN, body);

  return res.data;
};

export const sendPasswordOtp = async (body) => {
  const res = await axiosInstance.post(API.SEND_PASSWORD_OTP, body);
  return res.data;
};

export const verifyPasswordOtp = async (body) => {
  const res = await axiosInstance.post(API.VERIFY_RESET_PASSWORD_OTP, body);
  return res.data;
};

export const changePassword = async (body) => {
  const res = await axiosInstance.post(API.CHANGE_PASSWORD, body);
  return res.data;
};

export const getProfile = async () => {
  const res = await axiosInstance.get(`${API.GET_PROFILE}`);
  return res.data;
};

export const getUsersCount = async () => {
  const res = await axiosInstance.get(`${API.GET_USERS_COUNT}`);
  return res.data;
};

export const getMentorsCount = async () => {
  const res = await axiosInstance.get(`${API.GET_MENTORS_COUNT}`);
  return res.data;
};