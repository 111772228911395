
import { useMutation, useQuery } from 'react-query';
import {
  login,
  sendPasswordOtp,
  verifyPasswordOtp,
  changePassword,
  getProfile,
  getUsersCount,
  getMentorsCount,
} from '../api/auth';
import * as queryKey from '../queryKeys';

export const useLogin = (options = {}) => {
  const { mutate, isLoading } = useMutation(login, {
    mutationKey: queryKey.LOGIN,
    ...options,
  });
  return { mutate, isLoading };
};

export const useSendPasswordOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(sendPasswordOtp, {
    mutationKey: queryKey.SEND_PASSWORD_OTP,
    ...options,
  });
  return { mutate, isLoading };
};

export const useVerifyPasswordOtp = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(verifyPasswordOtp, {
    mutationKey: queryKey.VERIFY_RESET_PASSWORD_OTP,
    ...options,
  });

  return { mutate, isLoading, data };
};

export const useChangePassword = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(changePassword, {
    mutationKey: queryKey.CHANGE_PASSWORD,
    ...options,
  });

  return { mutate, isLoading, data };
};

export const useGetProfile = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PROFILE],
    getProfile,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetUsersCount = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_USERS_COUNT],
    getUsersCount,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetMentorsCount = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_EVENTS_COUNT],
    getMentorsCount,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};
