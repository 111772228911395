import { Box, useMediaQuery, Flex, Image } from '@chakra-ui/react';
import NonAuth from './NonAuthLayout/NonAuth';
import Header from './AuthLayout/Header';
import SideBar from './AuthLayout/SideBar';
import logos from '../../assets/ux-logo.png';
import vectorBg from '../../assets/vector-bg.jpeg';
export const AuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery('(max-width: 991px)');

  return (
    <Box
      overflowX={isMobile ? 'hidden' : ''}
      color="#000"
      bg="#fff"
      position="relative"
    >
      <Header />
      <Box minH="100vh" overflowX="hidden">
        {!isMobile && <SideBar />}
        <Box
          w="100%"
          pt={!isMobile ? '90px' : '120px'}
          minH="100vh"
          fontFamily="Inter"
          bg="#F9FAFB"
          color="#475367"
          pl={!isMobile ? '305px' : '20px'}
          pr={!isMobile ? '24px' : '20px'}
          pb={10}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export const NonAuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery('(max-width: 999px)');

  return (
    <Box
      bgImage={vectorBg}
      minH="100vh"
      bgRepeat="no-repeat"
      bgSize="cover"
      bgPos="top"
    >
      <Box className="wrapper" color="#000" fontFamily="Sansation">
        <Box pos="relative">
          <Box bg="#fff" pos="sticky" top="0" py="20px">
            <Flex>
              <Image src={logos} w="49px" h="49px" />
            </Flex>
          </Box>
          <Flex
            alignItems="center"
            h="70vh"
            gap="3rem"
            justifyContent="space-between"
          >
            {!isMobile && <NonAuth />}
            <Box flex="1" maxW="600px" mx="auto" w="full">
              {children}
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
