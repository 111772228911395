import { Box, Image, Text, Flex } from '@chakra-ui/react';

const NonAuth = () => {
  return (
    <Box>
      <Box>
        <Box className="font-heading" textAlign="left">
          <Text>Welcome to</Text>
          <Text>UX Thrive Admin</Text>
        </Box>
        <Text maxW="400px" width="full" textAlign="left">
          This system gives you the ability to manage UX Thrive and oversee the
          every day activity
        </Text>
      </Box>
    </Box>
  );
};
export default NonAuth;
