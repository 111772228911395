export const LOGIN = 'LOGIN';
export const SEND_PASSWORD_OTP = 'SEND_PASSWORD_OTP';
export const VERIFY_RESET_PASSWORD_OTP = 'VERIFY_RESET_PASSWORD_OTP';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_USERS_COUNT = 'GET_USERS_COUNT';
export const GET_EVENTS_COUNT = 'GET_EVENTS_COUNT';
export const ADD_MENTORS = 'ADD_MENTORS';
export const GET_ALL_MENTORS = 'GET_ALL_MENTORS';
export const GET_ALL_MEMBERS = 'GET_ALL_MEMBERS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_BOOTCAMP_USERS = 'GET_ALL_BOOTCAMP_USERS';
export const GET_ALL_TESTIMONIAL = 'GET_ALL_TESTIMONIAL';
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';