import { RxDashboard } from 'react-icons/rx';
import { TbUsers } from 'react-icons/tb';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { RiTeamLine } from 'react-icons/ri';
import { CiGift } from 'react-icons/ci';
import { SiGoogledocs } from 'react-icons/si';
import { CiCalendar } from 'react-icons/ci';
import { IoPersonAddOutline } from 'react-icons/io5';
import { IoLocationOutline } from 'react-icons/io5';
import { IoSettingsOutline } from 'react-icons/io5';
import { FiEdit } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { MdOutlineViewCarousel } from 'react-icons/md';
import { MdOutlineNoteAdd } from 'react-icons/md';
import { LiaProjectDiagramSolid } from 'react-icons/lia';
import { BsPersonWorkspace } from 'react-icons/bs';
import { IoPeopleSharp } from 'react-icons/io5';
import { GoPeople } from 'react-icons/go';
import { BiSolidEditAlt } from 'react-icons/bi';

export const data = [
  {
    title: 'Dashboard',
    icon: <RxDashboard />,
    path: '/dashboard',
  },
  {
    title: 'Users',
    icon: <TbUsers />,
    path: '/users',
  },
  {
    title: 'Mentors',
    icon: <FaChalkboardTeacher />,
    path: '/mentors',
  },
  {
    title: 'Team Members',
    icon: <RiTeamLine />,
    path: '/team-members',
  },
  {
    title: 'Projects',
    icon: <LiaProjectDiagramSolid />,
    path: '/projects',
  },
  {
    title: 'Challenge',
    icon: <BsPersonWorkspace />,
    path: '/challenge',
  },
  {
    title: 'Designers of the week',
    icon: <IoPeopleSharp />,
    path: '/designers',
  },
  {
    title: 'Events',
    icon: <CiGift />,
    path: '/events',
  },
  {
    title: 'Cohort',
    icon: <FaChalkboardTeacher />,
    path: '/cohort',
  },
  {
    title: 'Testimonials',
    icon: <MdOutlineNoteAdd />,
    path: '/testimonials',
  },
  {
    title: 'Admins',
    icon: <GoPeople />,
    path: '/admins',
  },
  {
    title: 'Settings',
    icon: <IoSettingsOutline />,
    path: '/settings',
  },
];

export const dashboardCard = [
  {
    count: '200',
    type: 'Total number of users',
    rate: '+1.01% this week',
  },
  {
    count: '200',
    type: 'Total number of mentors',
    rate: '+1.01% this week',
  },
  {
    count: '200',
    type: 'Total number of events',
    rate: '+1.01% this week',
  },
];

export const quickActions = [
  {
    icon: <CiCalendar />,
    action: 'Create an Event',
    title: 'Events',
  },
  {
    icon: <IoPersonAddOutline />,
    action: 'Add a mentor',
    title: 'Mentors',
  },
  {
    icon: <CiCalendar />,
    action: 'Add a team member',
    title: 'Team Members',
  },
];

export const userFilters = ['All Users', 'Bootcamp', 'Challenge'];

export const allUsersHeader = ['First Name', 'Last Name', 'Email Address', ''];

export const test = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

export const bootCamp = ['UI Design', 'Portfolio', 'Case Study'];

export const events = [
  {
    img: '/assets/event.png',
    name: 'UX Thrive Design Fest',
    date: '6th August, 2024',
    time: '10:00 - 12:00 (2 hours)',
    location: 'School of Science and Technology, Yaba, Lagos',
  },
  {
    img: '/assets/event.png',
    name: 'UX Thrive Design Fest',
    date: '6th August, 2024',
    time: '10:00 - 12:00 (2 hours)',
    location: 'School of Science and Technology, Yaba, Lagos',
  },
  {
    img: '/assets/event.png',
    name: 'UX Thrive Design Fest',
    date: '6th August, 2024',
    time: '10:00 - 12:00 (2 hours)',
    location: 'School of Science and Technology, Yaba, Lagos',
  },
];

export const testimonials = [
  {
    img: '/assets/event.png',
    name: 'Chinwe Okeke',
    role: 'UX Designer, Allround Inc',
    content:
      'UX Thrive has been a game-changer for my career. The community is incredibly supportive, and the resources are top-notch. I have learned so much from the webinars and discussions, and my design skills have improved significantly. Highly recommend it to anyone in the UX/UI field!',
  },
  {
    img: '/assets/event.png',
    name: 'Chinwe Okeke',
    role: 'UX Designer, Allround Inc',
    content:
      'UX Thrive has been a game-changer for my career. The community is incredibly supportive, and the resources are top-notch. I have learned so much from the webinars and discussions, and my design skills have improved significantly. Highly recommend it to anyone in the UX/UI field!',
  },
  {
    img: '/assets/event.png',
    name: 'Chinwe Okeke',
    role: 'UX Designer, Allround Inc',
    content:
      'UX Thrive has been a game-changer for my career. The community is incredibly supportive, and the resources are top-notch. I have learned so much from the webinars and discussions, and my design skills have improved significantly. Highly recommend it to anyone in the UX/UI field!',
  },
];

export const mentorActions = [
  {
    title: 'Edit Mentor',
    icon: <FiEdit />,
  },
  {
    title: 'Delete Mentor',
    icon: <MdDelete />,
  },
];

export const memberActions = [
  {
    title: 'Edit Member',
    icon: <FiEdit />,
  },
  {
    title: 'Delete Member',
    icon: <MdDelete />,
  },
];

export const designerActions = [
  {
    title: 'Delete Designer',
    icon: <MdDelete />,
  },
];

export const challengeActions = [
  {
    title: 'View Profile',
    icon: <MdOutlineViewCarousel />,
  },
  {
    title: 'Delete Profile',
    icon: <MdDelete />,
  },
];

export const uiChallengeActions = [
  {
    title: 'Edit Challenge',
    icon: <FiEdit />,
  },
  {
    title: 'Delete Challenge',
    icon: <MdDelete />,
  },
];

export const rolesActions = [
  {
    title: 'View Details',
    icon: <MdOutlineViewCarousel />,
  },
  {
    title: 'Delete Role',
    icon: <MdDelete />,
  },
];

export const viewEditDelete = [
  // {
  //   name: 'View Details',
  //   icon: <MdOutlineViewCarousel />,
  // },
  {
    name: 'Update Details',
    icon: <BiSolidEditAlt />,
  },
  {
    name: 'Delete Admin',
    icon: <MdDelete />,
  },
];
