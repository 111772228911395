import React, { useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Box,
  Flex,
  Text,
  CloseButton,
  Image,
  Spinner,
} from '@chakra-ui/react';
import { data } from '../../common/constant';
import logo from '../../../assets/ux-logo.png';
import { NavLink, useLocation } from 'react-router-dom';
import { sideScroll, useLogOut } from '../../../utils/helpers';
import { AiOutlineLogout } from 'react-icons/ai';

const SideDrawer = ({ isOpen, onClose }) => {

  const user = JSON.parse(sessionStorage.getItem('user'));
  const permissions = user?.data?.permissions;

  // Filter based on permissions
  const filteredData = data.filter((item) => {
    switch (item.title) {
      case 'Users':
        return permissions?.includes('MANAGE USERS');
      case 'Mentors':
        return permissions?.includes('MANAGE MENTORS');
      case 'Team Members':
        return permissions?.includes('MANAGE TEAM');
      case 'Projects':
        return permissions?.includes('MANAGE PROJECTS');
      case 'Admins':
        return permissions?.includes('MANAGE ADMIN');
      case 'Testimonials':
        return permissions?.includes('MANAGE TESTIMONIALS');
      case 'Cohort':
        return permissions?.includes('MANAGE COHORTS');
      case 'Events':
        return permissions?.includes('MANAGE EVENTS');
      case 'Challenge':
        return permissions?.includes('MANAGE CHALLENGES');
      case 'Designers of the week':
        return permissions?.includes('MANAGE DESIGNERS');
      default:
        return true; // For items that don't need special permissions
    }
  });

  const logout = useLogOut();
  const [isLoading, setIsLoading] = useState(false);

  const action = () => {
    setIsLoading(true);
    setTimeout(() => {
      logout();
      setIsLoading(false);
    }, 1000);
  };

  const activeStyle = {
    backgroundColor: '#DDD8FB',
    alignItems: 'center',
    display: 'flex',
    gap: '10px',
    color: '#101928',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0 0 8px 0',
    padding: '12px 16px ',
    borderRadius: '4px',
  };

  return (
    <Drawer
      autoFocus={false}
      size="xs"
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent py="20px" bgColor="#fff" color="#000">
        <DrawerBody p={0} overflowY="scroll">
          <Flex
            justifyContent="space-between"
            px="20px"
            mb="1.5rem"
            alignItems="center"
          >
            <Box bg="#FFFFFF">
              <Flex align="center" fontSize="22px" gap="10px">
                <Image src={logo} w="40px" h="38px" alt="logo" />
                <Box fontWeight={600}>UX Thrive</Box>
              </Flex>
            </Box>
            <Flex
              fontSize="12px"
              gap="5px"
              onClick={() => {
                onClose();
              }}
            >
              <CloseButton />
            </Flex>
          </Flex>
          <Box px="20px" flex="1">
            {filteredData?.map((item, i) => (
              <Box
                key={i}
                _hover={{
                  backgroundColor: '#DDD8FB',
                  borderRadius: '4px',
                }}
                onClick={onClose}
              >
                <NavLink
                  to={item.path}
                  style={({ isActive }) =>
                    isActive
                      ? { ...activeStyle }
                      : {
                          ...activeStyle,
                          backgroundColor: '',
                          color: '#101928',
                        }
                  }
                >
                  <Box fontSize="20px">{item.icon}</Box>
                  {item.title}
                </NavLink>
              </Box>
            ))}
          </Box>
          <Box
            mt="40px"
            // position="absolute"
            // bottom="20"
            left={0}
            right={0}
            borderTop="1px solid #314169"
            px={6}
            fontSize="14px"
            align="center"
            py="30px"
            fontWeight={400}
            cursor="pointer"
            onClick={action}
          >
            {isLoading ? (
              <Flex gap="8px" color="red" align="center">
                <Spinner size="sm" /> Logging Out
              </Flex>
            ) : (
              <Flex gap="8px" align="center" color="#b4b4b4">
                <AiOutlineLogout color="#B4B4B4" />
                Log Out
              </Flex>
            )}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
export default SideDrawer;
